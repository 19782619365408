import React, {useEffect, useRef, useState} from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import "../style.css";
import { Link, NavLink } from "react-router-dom";
import TermsAndConditionReplicaModal from "../components/Common/modals/ReplicaTerms";
import PolicyReplica from "../components/Common/modals/ReplicaPolicy";
import {useDispatch, useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import { format } from 'date-fns';
import {setSelectedLanguage} from "../store/reducers/replica";
import {useQueryClient} from "@tanstack/react-query";

function ReplicaLayout({ children }) {
  const [fillPercentage, setFillPercentage] = useState(0);
  const [isSticky, setIsSticky] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [showPolicy, setShowPolicy] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState({
    currency: false,
    country: false,
  });
  const dropdownCountryRef = useRef(null);
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient()
  const { t } = useTranslation();
  const terms = useSelector((state) => state?.replica?.home?.terms);
  const policy = useSelector((state) => state?.replica?.home?.policy);
  const companyDetails = useSelector((state) => state.replica?.companyDetails);
  const registerLink = useSelector((state) => state?.replica?.registerLink);
  const sponsor = useSelector((state) => state.replica?.sponsor);
  const multilangStatus = useSelector((state) => state.replica?.multilangStatus);
  const languages = useSelector((state) => state.replica?.languages);
  const selectedLanguage = useSelector((state) => state.replica?.selectedLanguage);

  const favicon = document.getElementById('dynamic-favicon');
  if (favicon) {
    favicon.href = companyDetails?.favicon;
  }
  const currentDate = new Date();
  const currentYear = format(currentDate, 'yyyy');

  // ---------------------------------------- API ----------------------------------------------

  if (params.username) localStorage.setItem("referralId", params.username);
  if (params.hashKey) localStorage.setItem("hashKey", params.hashKey);

  // --------------------------------------- Function -----------------------------------------
  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const scrollDown = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (scrollDown < 135) {
      setIsSticky(false);
    } else {
      setIsSticky(true);
    }

    const scrollPercentage =
      (scrollTop / (documentHeight - windowHeight)) * 100;

    const newFillPercentage = Math.min(Math.max(scrollPercentage, 0), 100);

    setFillPercentage(newFillPercentage);
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleNavLink = () => {
    setShowMobileMenu(false);
    const referralId = localStorage.getItem("referralId");
    if (referralId) navigate(`/${referralId}/`);
  };

  const toggleDropdown = (dropdown) => {
    setDropdownOpen((prevState) => ({
      currency: dropdown === "currency" ? !prevState.currency : false,
      country: dropdown === "country" ? !prevState.country : false,
    }));
  };

  const changeLanguage = (language) => {
    console.log(selectedLanguage);
    if (language.id !== selectedLanguage?.id) {
      dispatch(setSelectedLanguage(language));
      queryClient.invalidateQueries({
        predicate: (query) =>
            query.queryKey[0] === 'replica-home' || query.queryKey[0] === 'replica-register-fields',
      });
    }
    setDropdownOpen({ currency: false, country: false });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="pageReplica" id="pageReplica">
      { sponsor &&
        <div className="top__bar-four">
          <div className="custom__container">
            <div className="row">
              <div className="col-lg-8">
                <div className="top__bar-four-left lg-t-center">
                  <ul>
                    <li>
                      <span>
                        <i className="fa-solid fa-user"></i> {`${t("presented-by")} ${sponsor?.UserDetail?.fullName}`}
                      </span>
                      {/*<NavLink to="" target="" style={{ pointerEvents: "none" }}>
                        <i className="fa-solid fa-map-marker-alt"></i>
                        {`${t("Location")} :
                        ${companyDetails?.address ?? ""}`}
                      </NavLink>*/}
                    </li>
                    {/*<li>
                      <a href={"mailto:" + companyDetails?.email}>
                        <i className="fa-solid fa-envelope"></i>
                        {`${t("email")} :
                        ${companyDetails?.email ?? ""}`}
                      </a>
                    </li>*/}
                  </ul>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="top__bar-four-right">
                  {/* <h6>{`${t("follow_us")}:`}</h6>
                  <div className="top__bar-four-right-social">
                    <ul>
                      <li>
                        <a href="https://instagram.com/" target="_blank">
                          <i className="fa-brands fa-instagram"></i>
                          <span>{t("instagram")}</span>
                        </a>
                      </li>
                      <li>
                        <a href="https://youtube.com/" target="_blank">
                          <i className="fa-brands fa-youtube"></i>
                          <span>{t("youtube")}</span>
                        </a>
                      </li>
                      <li>
                        <a href="https://linktr.ee/" target="_blank">
                          <svg xmlns="http://www.w3.org/2000/svg" shapeRendering="geometricPrecision"
                               textRendering="geometricPrecision" imageRendering="optimizeQuality"
                               fillRule="evenodd" clipRule="evenodd" viewBox="0 0 417 512.238">
                            <path fill="#ffffff" fillRule="nonzero" d="M171.274 344.942h74.09v167.296h-74.09V344.942zM0 173.468h126.068l-89.622-85.44 49.591-50.985 85.439 87.829V0h74.086v124.872L331 37.243l49.552 50.785-89.58 85.24H417v70.502H290.252l90.183 87.629L331 381.192 208.519 258.11 86.037 381.192l-49.591-49.591 90.218-87.631H0v-70.502z"/>
                          </svg>
                          <span>{t("linktree")}</span>
                        </a>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <div className={`header__sticky ${isSticky ? "header__sticky-sticky-menu" : ""}`}>
        <div className="container custom__container">
          <div className="header__area-menubar p-relative">
            <div className="header__area-menubar-left">
              <div className="header__area-menubar-left-logo">
                <NavLink to="" onClick={handleNavLink}>
                  <img
                    className="dark-n"
                    src={companyDetails?.logo ?? "/img/logo.png"}
                    alt="Logo"
                  />
                  <img
                    className="light-n"
                    src={companyDetails?.logo ?? "/img/logo-1.png"}
                    alt="Logo"
                  />
                </NavLink>
              </div>
            </div>
            <div className="header__area-menubar-center">
              <div className="header__area-menubar-center-menu four menu-responsive">
                <ul
                  id="mobilemenu"
                  className={`${showMobileMenu ? "menu_open" : ""}`}
                >
                  <li>
                    <a href="#home" onClick={handleNavLink}>
                      {t("home")}
                    </a>
                  </li>

                  <li>
                    <a href="#aboutus" onClick={handleNavLink}>
                      {t("about_us")}
                    </a>
                  </li>
                  <li>
                    <a href="#services" onClick={handleNavLink}>
                      {t("services")}
                    </a>
                  </li>
                  <li>
                    <a href="#contact" onClick={handleNavLink}>
                      {t("contact_us")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="header__area-menubar-right">
              <div className="header__area-menubar-right-responsive-menu menu__bar two">
                <i
                  className="flaticon-dots-menu"
                  onClick={() => setShowMobileMenu(true)}
                ></i>
              </div>
              {/*<div className="header__area-menubar-right-contact">
                <div className="header__area-menubar-right-contact-icon">
                  <a href={"mailto:"+companyDetails?.email}><i className="fa-light fa-envelope-open-text"></i></a>
                </div>
                <div className="header__area-menubar-right-contact-info">
                  <span>{t("message")}</span>
                  <h6>
                    <a href={"mailto:"+companyDetails?.email}>{companyDetails?.email}</a>
                  </h6>
                </div>
              </div>*/}
              {multilangStatus && (
                  <div
                      className={`header__area-menubar-right-btn top_dropdown country_dropdown ${dropdownOpen.country ? "show" : ""
                      }`}
                      ref={dropdownCountryRef}
                  >
                    <button
                        className="dropdown-toggle"
                        onClick={() => toggleDropdown("country")}
                        data-bs-toggle="dropdown"
                        aria-expanded={dropdownOpen.country}
                    >
                      <img src={`/${selectedLanguage?.flagImage}`} alt="" />
                    </button>
                    <div
                        className={`dropdown-menu usr_prfl animation slideDownIn ${dropdownOpen.country ? "show" : ""
                        }`}
                    >
                      <div className="usr_prfl_setting">{t("language")}</div>
                      <ul className="">
                        {languages?.map((item, index) => (
                            <li key={index}>
                              <button
                                  className="dropdown-item"
                                  onClick={() => changeLanguage(item)}
                              >
                                <img src={`/${item?.flagImage}`} alt="" /> {item.name}
                              </button>
                            </li>
                        ))}
                      </ul>
                    </div>
                  </div>
              )}
              <div className="header__area-menubar-right-btn four">
                {location.pathname !== "/replica-register" && !!sponsor && (
                  <Link className="btn-one" to={registerLink}>
                    {t("register")}{" "}
                  </Link>
                )}
              </div>
              <div className="header__area-menubar-right-btn four">
                <Link className="btn-one" to={"/login"}>
                  {t("login")}{" "}
                </Link>
              </div>
            </div>
          </div>
          <div
            className={`menu__bar-popup four ${showMobileMenu ? "show" : ""}`}
          >
            <div className="menu__bar-popup-close">
              <i
                className="fa-light fa-times"
                onClick={() => setShowMobileMenu(false)}
              ></i>
            </div>
            <div className="menu__bar-popup-left">
              <div className="menu__bar-popup-left-logo">
                <a href="/">
                  <img src={companyDetails?.logoDark} alt="logo" />
                </a>
                <div className="responsive-menu"></div>
              </div>
              <div className="menu__bar-popup-left-social">
                {/* <h6>{t("follow_us")}</h6>
                <ul>
                  <li>
                    <a href="https://www.facebook.com/" target="">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/" target="">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/" target="">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://dribbble.com/" target="">
                      <i className="fab fa-dribbble"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.behance.net/" target="">
                      <i className="fab fa-behance"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/" target="">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/" target="">
                      <i className="fab fa-youtube"></i>
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ---------------------------------------- Set child components here -------------------------------------- */}

      {children}

      {/* --------------------------------------------------------------------------------------------------------- */}

      <div
        className="footer__two"
        style={{ backgroundImage: "url(/img/shape/footer-bg.png)" }}
      >
        <div className="container">
          <div className="row">
            {/* <div className="col-xl-6 col-lg-6 col-sm-12">
              <div className="footer__two-widget">
                <div className="footer__two-widget-about">
                  <h6>{t("follow_us")}</h6>
                  <div className="footer__two-widget-about-social">
                    <ul>
                      <li>
                        <a href="https://www.facebook.com/" target="">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/" target="">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.behance.net/" target="">
                          <i className="fab fa-behance"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://dribbble.com/" target="">
                          <i className="fab fa-dribbble"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-xl-3 col-lg-3 col-sm-12">
              <div className="footer__two-widget">
                <h4>{t("main_pages")}</h4>
                <div className="footer__area-widget-menu four">
                  <ul>
                    <li>
                      <a href="#home" onClick={handleNavLink}>
                        {t("home")}
                      </a>
                    </li>

                    <li>
                      <a href="#aboutus" onClick={handleNavLink}>
                        {t("about_us")}
                      </a>
                    </li>
                    <li>
                      <a href="#services" onClick={handleNavLink}>
                        {t("services")}
                      </a>
                    </li>
                    <li>
                      <a href="#contact" onClick={handleNavLink}>
                        {t("contact_us")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-sm-12">
              <div className="footer__two-widget">
                <h4>{t("quick_links")}</h4>
                <div className="footer__area-widget-menu four">
                  <ul>
                    <li>
                      <button onClick={() => setShowTerms(true)}>
                        {t("terms_and_condition")}
                      </button>
                    </li>
                    <li>
                      <button onClick={() => setShowPolicy(true)}>
                        {t("privacy_policy")}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright__one">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <p>
                  Copyright {currentYear}{" "}
                  <a href="#home" onClick={() => handleNavLink}>
                    {companyDetails?.name}
                  </a>{" "}
                  - All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`scroll-up scroll-four ${fillPercentage > 0 ? "active-scroll" : ""
          }`}
        onClick={handleScrollToTop}
      >
        <svg
          className="scroll-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path
            d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
            strokeDasharray="307.919"
            strokeDashoffset={(307.919 * (100 - fillPercentage)) / 100}
          />
        </svg>
      </div>
      <TermsAndConditionReplicaModal
        show={showTerms}
        setShow={setShowTerms}
        data={terms}
      />
      <PolicyReplica show={showPolicy} setShow={setShowPolicy} data={policy} />
    </div>
  );
}

export default ReplicaLayout;
