import React from "react";
import TableContent from "../Common/table/TableContent";
import TablePagination from "../Common/table/TablePagination";
import { ApiHook } from "../../hooks/apiHook";
import { useState } from "react";
import { useTranslation } from "react-i18next";

function HoldingTankTable(props) {
  const { t } = useTranslation();
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const { data: { data: holdingTank } = { data: { currentPage: 1, totalPages: 1 }} } = ApiHook.CallHoldingTank({
    page: props.currentPage,
    itemsPerPage
  });
  const headers = [t("member_name"), t("joinDate"), t("teamCount")];

  let startPage = 1;
  const toNextPage = () => {
    if (holdingTank?.currentPage < holdingTank?.totalPages) {
      props.setCurrentPage(holdingTank.currentPage + 1);
    }
  };

  const toLastPage = () => {
    props.setCurrentPage(holdingTank?.totalPages);
  };
  const toPreviousPage = () => {
    if (holdingTank?.currentPage > startPage) {
      props.setCurrentPage(holdingTank.currentPage - 1);
    }
  };

  const toFirstPage = () => {
    props.setCurrentPage(startPage);
  };
  const handleItemsPerPageChange = (event) => {
    const selectedValue = parseInt(event.target.value);
    setItemsPerPage(selectedValue);
    props.setCurrentPage(1);
  };

  return (
    <div className="ewallet_table_section">
      <div className="ewallet_table_section_cnt">
        <div className="filter_Section">
          <div className="row justify-content-between align-items-center">
            <div className="col-xl-4 col-md-6">
              <div className="right_search_div d-flex gap-1">
                <div className="downMembBg">
                  <h5>{t("totalHoldingTank")}</h5>
                  <h4>{holdingTank?.totalCount ?? "0"}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive min-height-table">
          <TableContent
            headers={headers}
            data={holdingTank?.data}
            type="holding-tank"
          />
          {holdingTank?.data &&
              holdingTank?.data?.length !== 0 && (
              <TablePagination
                startPage={startPage}
                currentPage={holdingTank?.currentPage}
                totalPages={holdingTank?.totalPages}
                setCurrentPage={props.setCurrentPage}
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                toNextPage={toNextPage}
                toLastPage={toLastPage}
                toPreviousPage={toPreviousPage}
                toFirstPage={toFirstPage}
                handleItemsPerPageChange={handleItemsPerPageChange}
              />
            )}
        </div>
      </div>
    </div>
  );
}

export default HoldingTankTable;
