import {
  DownlineMembersService,
  GenealogyService,
  ReferralMembersService,
  SponserTreeService,
  TreeViewService,
  HoldingTankService
} from "../../services/tree/network";

export const GenealogyActions = {
  getTreelist: async (userId, userName) => {
    try{
      return await GenealogyService.getTreelist(userId, userName);
    } catch (error) {
      return error.message
    }
  },
  getUnilevelMore: async (fatherId, position) => {
    try {
      return await GenealogyService.getUnilevelMore(fatherId, position);
    } catch (error) {
      return error.message
    }
  }
};

export const TreeViewActions = {
  getTreelist: async (userId) => {
    try {
      return await TreeViewService.getTreelist(userId);
    } catch (error) {
      return error.message
    }
  }
};

export const SponserTreeActions = {
  getTreelist: async (userId, userName) => {
    try {
      return await SponserTreeService.getTreelist(userId, userName);
    } catch (error) {
      return error.message
    }
  }
};
export const downlineMembersActions={
  getDownlineMembers:async(level,page,itemsPerPage)=>{
    try {
      return await DownlineMembersService.callDownline(level, page, itemsPerPage);
    } catch (error) {
      return error.message
    }
  },
  getDownlineheaders:async()=>{
    try {
      return await DownlineMembersService.callHeader();
    } catch (error) {
      return error.message
    }
  }

}

export const ReferralMembersActions={
  getReferralmembers:async(level,page,itemsPerPage)=>{
    try {
      return await ReferralMembersService.callReferral(level, page, itemsPerPage);
    } catch (error) {
      return error.message
    }
  },
  getRferralHeader:async()=>{
    try {
      return await ReferralMembersService.callHeader();
    } catch (error) {
      return error.message
    }
  }
}

export const HoldingTankActions = {
  getHoldingTank: async (page, itemsPerPage) => {
    try {
      return await HoldingTankService.getHoldingTank(page, itemsPerPage);
    } catch (error) {
      return error.message
    }
  },
  callHoldingTankPlacement: async (data) => {
    try {
      return await HoldingTankService.callHoldingTankPlacement(data)
    } catch (error) {
      return error.message
    }
  }
}