import { createSlice } from '@reduxjs/toolkit';
import i18n from "i18next";

const userLanguage = JSON.parse(localStorage.getItem("userLanguage"));

const initialState = {
  companyDetails: { data: {} },
  home: { data: {} },
  registerLink: { data: {} },
  registerFields: {},
  sponsor: { data: {} },
  multilangStatus: false,
  languages: [],
  selectedLanguage: userLanguage,
};

const userSlice = createSlice({
  name: 'replica',
  initialState,
  reducers: {
    setCompanyDetails: (state, action) => {
      state.companyDetails = action.payload
    },
    setHome: (state, action) => {
      state.home = { ...action.payload, ...state }
    },
    setRegisterLink: (state, action) => {
      state.registerLink = action.payload
    },
    setRegisterFields: (state, action) => {
      state.registerFields = action.payload
    },
    setSponsor: (state, action) => {
      state.sponsor = action.payload
    },
    setMultilangStatus: (state, action) => {
      state.multilangStatus = action.payload
    },
    setLanguages: (state, action) => {
      state.languages = action.payload
    },
    setSelectedLanguage: (state, action) => {
      i18n.changeLanguage(action.payload?.code);
      state.selectedLanguage = action.payload
      localStorage.setItem("userLanguage", JSON.stringify(action.payload));
    },
  },
});

export const { setCompanyDetails, setHome, setRegisterLink, setRegisterFields, setSponsor, setMultilangStatus, setLanguages, setSelectedLanguage } = userSlice.actions;

export default userSlice.reducer;
