import React, {useEffect, useRef, useState} from 'react';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import {formatDate} from "../../utils/formateDate";
import {useTranslation} from "react-i18next";

const UserPopover = React.forwardRef(({noProfile, profilePic, username, fullName, tableData, rankDetails, containerSize, ...props}, ref) => {
    const { t } = useTranslation();
    const internalRef = useRef(null);

    function useMergedRefs(...refs) {
        const targetRef = useRef(null);

        useEffect(() => {
            refs.forEach(ref => {
                if (!ref) return;

                if (typeof ref === 'function') {
                    ref(targetRef.current);
                } else {
                    ref.current = targetRef.current;
                }
            });
        }, [refs]);

        return targetRef;
    }

    const mergedRef = useMergedRefs(ref, internalRef);

    const [copied, setCopied] = useState(false);

    const copyToClipboard = async (text) => {
        if (navigator && navigator.clipboard) {
            try {
                await navigator.clipboard.writeText(text);
                setCopied(true);
                // Reset copied status after a delay (e.g., 2 seconds)
                setTimeout(() => {
                    setCopied(false);
                }, 2000);
            } catch (error) {
                console.error("Clipboard copy failed:", error);
            }
        }
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const renderPopover = (content) => (
        <Popover>
            <Popover.Body>{`${content}`}</Popover.Body>
        </Popover>
    );

    useEffect(() => {
        if (props.popper) {
            props.popper.scheduleUpdate();
        }
    }, [containerSize?.width, containerSize?.height, props.popper]);

    return (
        <Popover
            id="treeview_pop"
            onClick={stopPropagation}
            onDoubleClick={stopPropagation}
            ref={mergedRef}
            {...props}>
            <div className="card">
                <div className="card-img-top">
                    <div className="card-img-top_img">
                        <img src={profilePic ?? noProfile} alt="profile_photo"/>
                    </div>
                    <OverlayTrigger placement="top" overlay={renderPopover(copied ? t("copied") : t("copyUsername"))}>
                        <h5 className="card-title" role="button" onClick={() => copyToClipboard(username)}>
                            {username} <i className="fa-light fa-copy"></i>
                        </h5>
                    </OverlayTrigger>
                    <p className="card-text">
                        {fullName ?? "username"}
                    </p>
                </div>
                <div className="card-body">
                    <div className="user_detail_tabl">
                        <table>
                            <tbody>
                            {tableData &&
                                Object.entries(tableData).map(
                                    ([key, value]) => (
                                        <tr key={key}>
                                            <td>{t(key)}</td>
                                            <td>{
                                                (key === "joinDate" && formatDate(value))
                                                || (typeof value !== "object" && (!isNaN(value) ? value.toLocaleString() : value))
                                            }</td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                    {rankDetails && rankDetails?.name && (
                        <button className="btn btn-rank" style={{backgroundColor: rankDetails?.color}}>{rankDetails?.name}</button>
                    )}
                </div>
            </div>
        </Popover>
    );
});

export default UserPopover;