import React, { useEffect, useRef, useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import anime from "animejs/lib/anime.es.js";
import { ApiHook } from "../../../hooks/apiHook";
import Loader from "react-js-loader";
import { updateSponserTree } from "../../../store/reducers/treeReducer";
import UserPopover from "../UserPopover";

const SponserTreeNode = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = props?.data;

  const NoProfile = "/images/user-profile.png";
  const [expanded, setExpanded] = useState(true);
  const [showPopover, setShowPopover] = useState(false);
  const listRef = useRef(null);
  const updatedTreeMutation = ApiHook.CallSponserTreeMore();
  const setSelectedUserId = props.setSelectedUserId;
  const setDoubleClickedUser = props.setDoubleClickedUser;

  let singleClickTimer = null;
  const HandleClick = (data, event) => {
    clearTimeout(singleClickTimer); // Clear any existing single-click timer

    if (event.detail === 2) {
      // Double-click detected
      setSelectedUserId("");
      setDoubleClickedUser(data?.attributes?.id);
    } else {
      // Single-click logic
      singleClickTimer = setTimeout(() => {
        if (data?.children.length === 0 && data?.attributes.childrenCount > 0) {
          setDoubleClickedUser("");
          setSelectedUserId(data?.attributes?.id);
        } else {
          setExpanded(!expanded);
        }
        clearTimeout(singleClickTimer); // Clear the timer after executing the single-click logic
      }, 200); // Adjust the time according to your needs
    }
  };

  const handleIsMore = (data) => {
    updatedTreeMutation.mutateAsync(data, {
      onSuccess: (res) => {
        if (res.status) {
            dispatch(
                updateSponserTree({
                    fatherId: data?.fatherId,
                    position: data?.position,
                    newChildren: res?.data
                })
            )
        }
    },
    });
  };

  const handleMouseLeave = (e) => {
    const relatedTarget = e.relatedTarget;
    const currentTarget = e.currentTarget;
  
    if (relatedTarget instanceof Node && currentTarget instanceof Node) {
      const isMouseOutsidePopover = !relatedTarget || !currentTarget.contains(relatedTarget);
  
      if (isMouseOutsidePopover) {
        setShowPopover(false);
      }
    }else{
      setShowPopover(false);
    }
  };

  useEffect(() => {
    if (expanded) {
      anime({
        targets: listRef.current,
        translateY: [`${-20}px`, `${0}px`],
        opacity: [0, 1],
        duration: 500,
        easing: "easeInQuad",
      });
    }
  }, [expanded]);

  return (
    <li>
      {props.data?.isPlaceholder ? (
        <>
          <div className="member-view-box">
            <div className="member-image">
              {props?.data?.isMore && (
                <>
                  {updatedTreeMutation.status === "loading" ? (
                    <>
                      <Loader
                        type="spinner-default"
                        bgColor={"#485742"}
                        color={"#485742"}
                        size={25}
                      />
                    </>
                  ) : (
                    <>
                      <div
                        className="right_more_user_expand_btn"
                        onClick={() => handleIsMore(props?.data?.attributes)}
                      >
                        <i className="fa-solid fa-angle-double-right"></i>
                      </div>
                      <div className="member-details-dwnl-bx">
                        {props?.data?.attributes?.moreChildren} {t("more")}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          {props?.data?.children?.length === 0 &&
          props?.data?.attributes?.id === props?.selectedUserId ? (
            <div className="member-view-box">
              <div className="member-image">
                <Loader
                  type="spinner-default"
                  bgColor={"#485742"}
                  color={"#485742"}
                  size={25}
                />
              </div>
            </div>
          ) : (
            <span
              onClick={(e) => HandleClick(data, e)}
              onMouseEnter={() => setShowPopover(true)}
              onMouseLeave={handleMouseLeave}
            >
              <div className="member-view-box">
                <OverlayTrigger
                    trigger="manual"
                    placement="bottom"
                    delay="250"
                    overlay={<UserPopover noProfile={NoProfile} {...data?.tooltipData} containerSize={props.containerSize} />}
                    container={props.containerRef}
                    show={showPopover}
                >
                  <div className="member-image">
                    <img src={data?.attributes?.treeIcon ?? NoProfile} alt="Member"/>
                    <div className="member-details">
                      <h3>{data?.username}</h3>
                      <div className="member-details-downline-dtl">
                        <div className="member-details-dwnl-bx">
                          {t("count")}:{" "}
                          <strong>
                            {data?.attributes?.childrenCount}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </OverlayTrigger>
              </div>
            </span>
          )}
          {data?.children?.length > 0 && expanded && (
            <ul ref={listRef}>
              {data?.children?.map((child, index) => {
                return (
                  <SponserTreeNode
                    key={index}
                    data={child}
                    plan={props.plan}
                    selectedUserId={props.selectedUserId}
                    setSelectedUserId={props.setSelectedUserId}
                    doubleClickedUser={props.doubleClickedUser}
                    setDoubleClickedUser={props.setDoubleClickedUser}
                    containerRef={props.containerRef}
                    containerSize={props.containerSize}
                  />
                );
              })}
            </ul>
          )}
        </>
      )}
    </li>
  );
};
const SponserTreeComponent = (props) => {
  const mlmPlan = useSelector((state) => state.user?.loginResponse?.moduleStatus?.mlm_plan);
  const data = useSelector((state) => state.tree?.sponserTreeList);

  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const container = props.containerRef.current;
    const observer = new ResizeObserver((entries) => {
      setContainerSize({ width: entries[0].contentRect['width'], height: entries[0].contentRect['height'] });
    });
    observer.observe(container);

    return () => {
      if(container){
        observer.unobserve(container);
      } else {
        observer.disconnect();
      }
    }
  });

  return (
    <SponserTreeNode
      data={data}
      plan={mlmPlan}
      selectedUserId={props.selectedUserId}
      setSelectedUserId={props.setSelectedUserId}
      doubleClickedUser={props.doubleClickedUser}
      setDoubleClickedUser={props.setDoubleClickedUser}
      containerRef={props.containerRef}
      containerSize={containerSize}
    />
  );
};
export default SponserTreeComponent;