import React, { useState } from "react";
import HoldingTankTable from "../../components/Tree/HoldingTankTable";
import TreeViewNavbar from "../../components/Tree/TreeViewNavbar";

function HoldingTank() {
  const [currentPage, setCurrentPage] = useState(1);
  
  return (
    <>
      <TreeViewNavbar menu={"holdingTank"} />
      <HoldingTankTable
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}

export default HoldingTank;
