import API from "../../api/api";

const callApi = async (endpoint, method = 'get', body = null) => {
    try {
        let response;
        method = method.toLowerCase();

        if (method === 'get') {
            response = await API.get(endpoint);
        } else if (method === 'post') {
            response = await API.post(endpoint, body);
        } else if (method === 'delete') {
            response = await API.delete(endpoint, {data: JSON.stringify(body)});
        } else if (method === 'patch') {
            response = await API.patch(endpoint, body);
        }

        if (response?.data) return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }

    const unsupported = `Unsupported HTTP method: ${method}`;
    console.log(unsupported);
    throw new Error(unsupported);
};
export const PartyServices = {
    setupParty: async (file, data) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("partyData", JSON.stringify(data));

        try {
            return (await API.post(`add-party?type=party`, formData, {headers: {'Content-Type': 'multipart/form-data'}})).data;
        } catch (error) {
            return error.response.data;
        }
    },
    addHost: (body) => callApi("manage-host", 'post', body),
    updateHost: (body) => callApi("manage-host", 'post', body),
    addGuest: (body) => callApi("manage-guest", 'post', body),
    updateGuest: (body) => callApi("manage-guest", 'post', body),
    getPartyGuests: (perPage, page) => callApi(`party-guests?perPage=${perPage}&page=${page}`, 'get'),
    getPartyHosts: (perPage, page) => callApi(`party-hosts?perPage=${perPage}&page=${page}`, 'get'),
    getAllParties: () => callApi("all-parties", 'get'),
    getPartyDetails: (id) => callApi(`get-party?partyId=${id}`, 'get'),
    inviteGuests: (body) => callApi("invite-guest", 'post', body),
    getGuestOrders: (partyId, perPage, page) => callApi(`get-guest-orders?partyId=${partyId}&page=${page}&perPage=${perPage}`, 'get'),
    getPartyProducts: (partyId, guestId) => callApi(`party-products?partyId=${partyId}&guestId=${guestId}`),
    deleteGuestOrder: (body) => callApi("delete-guest-order", 'delete', body),
    createGuestOrder: (body) => callApi("create-guest-order", 'post', body),
    getPartyGuestsById: (id) => callApi(`get-guests-by-party?partyId=${id}`, 'get'),
    patchprocessOrders: (body) => callApi('process-guest-order', 'patch', body),
    closeParty: (body) => callApi(`end-party`, 'patch', body),
    addToPartyCart: (body) => callApi(`create-guest-order`, 'post', body),
    timeValidation:(body) => callApi(`/validate-party-timings?fromDate=${body.fromDate}&fromTime=${body.fromTime}&toDate=${body.toDate}&toTime=${body.toTime}`)
};