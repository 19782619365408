import React, { useEffect, useRef, useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import anime from "animejs/lib/anime.es.js";
import Loader from "react-js-loader";
import UserPopover from "../UserPopover";

const TreeViewNode = ({
  data,
  setSelectedUserId,
  selectedUserId,
  isLoading,
  ...props
}) => {
  const NoProfile = "/images/user-profile.png";
  const [expanded, setExpanded] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const treeviewContentRef = useRef(null);

  const handleClick = (data) => {
    if (data?.hasChildren) {
      setSelectedUserId(data?.id);
      setExpanded(!expanded);
    }
  };

  const handleMouseLeave = (e) => {
    const relatedTarget = e.relatedTarget;
    const currentTarget = e.currentTarget;

    if (relatedTarget instanceof Node && currentTarget instanceof Node) {
      const isMouseOutsidePopover =
          !relatedTarget || !currentTarget.contains(relatedTarget);

      if (isMouseOutsidePopover) {
        setShowPopover(false);
      }
    } else {
      setShowPopover(false);
    }
  };

  useEffect(() => {
    if (expanded) {
      anime({
        targets: treeviewContentRef.current,
        translateY: [`${-20}px`, `${0}px`],
        opacity: [0, 1],
        duration: 500,
        easing: "easeInQuad",
      });
    }
  }, [expanded]);

  return (
    <li>
      {data?.children && (
        <span onClick={() => handleClick(data)}>
          {data?.hasChildren ? (
            expanded ? (
              <i className="fa fa-minus-square"></i>
            ) : (
              <i className="fa fa-plus-square"></i>
            )
          ) : null}
          {isLoading && data?.id === selectedUserId ? (
            <Loader
              type="bubble-scale"
              bgColor={"#485742"}
              color={"#485742"}
              size={25}
            />
          ) : (
            <div
              className="treeview_content_box"
              onMouseEnter={() => setShowPopover(true)}
              onMouseLeave={handleMouseLeave}
            >
              <div className="treeview_content_box_img">
                <img
                  src={data?.profilePic ? data?.profilePic : NoProfile}
                  alt=""
                />
              </div>
              <div className="treeview_content_box_usr_dtl">
                <strong>{data?.title}</strong>
                <div style={{ overflow:"hidden", textOverflow:"ellipsis", width:"125px"}}>{data?.fullName}</div>
              </div>
              <div className="treeview_content_box_left_level">
                <strong>{data?.level}</strong>Level
              </div>
              <OverlayTrigger
                trigger="manual"
                placement="auto"
                delay="250"
                overlay={<UserPopover noProfile={NoProfile} {...data?.tooltipData} containerSize={props.containerSize} />}
                container={props.containerRef}
                show={showPopover}
              >
                <div className="treeview_content_box_left_info">
                  <i className="fa fa-info"></i>
                </div>
              </OverlayTrigger>
            </div>
          )}
        </span>
      )}
      {!data.children && (
        <div className="treeview_content_box">
          <div className="treeview_content_box_img">
            <img src={data.image} alt="" />
          </div>
          <div className="treeview_content_box_usr_dtl">
            <strong>{data.id}</strong>
            <div>{data.name}</div>
          </div>
          <div className="treeview_content_box_left_level">
            <strong>{data.level}</strong>Level
          </div>
          <div className="treeview_content_box_left_info">
            <i className="fa fa-info"></i>
          </div>
        </div>
      )}
      {expanded && data.children && (
        <ul
          ref={treeviewContentRef}
          style={{ transform: "translateY(-20px)", opacity: 0 }}
        >
          {data?.children?.map((child) => (
            <TreeViewNode
              key={child.id}
              data={child}
              setSelectedUserId={setSelectedUserId}
              selectedUserId={selectedUserId}
              isLoading={isLoading}
              containerRef={props.containerRef}
              containerSize={props.containerSize}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

export default TreeViewNode;
