import replicaAPI from "../../api/replicaApi";
import API from "../../api/api";

const callApi = async (endpoint) => {
  try {
    const response = await replicaAPI.get(endpoint);
    if (response.status === 200) {
      return response?.data;
    } else {
      return response;
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const ReplicaService = {
  getApiKey: async (admin) => {
    const adminUsername = admin ?? localStorage.getItem("admin_user_name");
    return await callApi(
        `get-api-key?admin_username=${adminUsername}`
    );
  },
  getReplicaHome: async (userLanguage) => {
    return await callApi("replica-home" + ( userLanguage?.id ? "?languageId=" + userLanguage.id : ""));
  },
  getReplicaRegister: async (userLanguage) => {
    return await callApi("replica-register-get" + ( userLanguage?.id ? "?languageId=" + userLanguage.id : ""));
  },
  getFieldCheck: async (field, value) => {
    return await callApi(
        `replica-checkUsernameEmail?field=${field}&value=${value}`
    );
  },
  callBankUpload: async (data, username, referralId, type) => {
    const formData = new FormData();
    formData.append("file", data);
    formData.append("username", username);
    formData.append("referralId", referralId);

    try {
      return (await API.post(`replica-payment-receipt-upload?type=${type}`, formData, {headers: {'Content-Type': 'multipart/form-data'}})).data;
    } catch (error) {
      return error.response.data;
    }
  },
  ReplicaBankRecieptDelete: async (data) => {
    return replicaAPI
      .post(`replica-payment-receipt-delete`, data)
      .then((response) => response.data)
      .catch((error) => error);
  },
  CallReplicaRegister: async (data) => {
    return replicaAPI
      .post("replica-register-post", JSON.stringify(data))
      .then((response) => response.data)
      .catch((error) => error);
  },
  replicaContactUpload: async (data) => {
    return replicaAPI
      .post("replica-contact-upload", JSON.stringify(data))
      .then((response) => response.data)
      .catch((error) => error);
  },
  replicaCreateStripePaymentIntent: async (data) => {
    return replicaAPI
      .post("replica-create-stripe-payment-intent", JSON.stringify(data))
      .then((response) => response.data)
      .catch((error) => error);
  },
  replicaUpdateStripePaymentIntent: async (data) => {
    return replicaAPI
      .post("replica-update-stripe-payment-intent", JSON.stringify(data))
      .then((response) => response.data)
      .catch((error) => error);
  },
  replicaCancelStripePaymentIntent: async (data) => {
    return replicaAPI
      .post("replica-cancel-stripe-payment-intent", JSON.stringify(data))
      .then((response) => response.data)
      .catch((error) => error);
  },
};
