import React, { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import { ApiHook } from "../../../hooks/apiHook";
import { useLocation, useNavigate } from "react-router-dom";
import anime from "animejs/lib/anime.es.js";
import Loader from "react-js-loader";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import UserPopover from "../UserPopover";
import {toast} from "react-toastify";
import {stringWithPlaceholders} from "../../../utils/stringWithPlaceholders";
import {useQueryClient} from "@tanstack/react-query";

const TreeNode = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const data = props?.data;
  const plan = props?.plan;

  const NoProfile = "/images/user-profile.png";
  const [expanded, setExpanded] = useState(true);
  const [hoveredItemId, setHoveredItemId] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const [showPopoverMenu, setShowPopoverMenu] = useState(false);
  const [showHoldingTank, setShowHoldingTank] = useState(false);
  const [holdingTankUser, setHoldingTankUser] = useState(null);
  const listRef = useRef(null);

  const holdingTankPlacementMutation = ApiHook.CallHoldingTankPlacement();

  let singleClickTimer = null;
  const HandleClick = (data, event) => {
    clearTimeout(singleClickTimer); // Clear any existing single-click timer

    if (event.detail === 2) {
      // Double-click detected
      props.setSelectedUserId("");
      props.setDoubleUser(data?.attributes?.id);
    } else {
      // Single-click logic
      singleClickTimer = setTimeout(() => {
        if (data?.children.length === 0) {
          props.setDoubleUser("");
          props.setSelectedUserId(data?.attributes?.id);
        } else {
          setExpanded(!expanded);
        }
        clearTimeout(singleClickTimer); // Clear the timer after executing the single-click logic
      }, 200); // Adjust the time according to your needs
    }
  };

  const handleItemHover = (itemId) => {
    setHoveredItemId(itemId);
  };

  const handleIsMore = (data) => {
    let refetch = false;
    if (
      data?.fatherId === props.isMoreId.fatherId &&
      data?.position === props.isMoreId?.position
    ) {
      refetch = true;
    }
    props.setIsMoreId((prev) => ({
      ...prev,
      fatherId: data?.fatherId,
      position: data?.position,
    }));
    if (refetch) {
      props.updatedTree?.refetch();
    }
  };

  const handleRegistration = (data) => {
    props.setParamsList({
      placement: data?.attributes?.parent,
      position: data?.attributes?.position,
      isRegFromTree: 1,
    });

    if (props.ecomStatus) {
      props.setLinkRegisterCheck(true);
    } else if (location.pathname.substring(1) === "genealogy-tree-web") {
      const parent = data?.attributes?.parent;
      const position = data?.attributes?.position;
      window.location.href = `/register/${parent}/${position}`;
    } else {
      navigate("/register", {
        state: {
          parent: data?.attributes?.parent,
          position: data?.attributes?.position,
        },
      });
    }
  };

  const handleMouseLeave = (e) => {
    const relatedTarget = e.relatedTarget;
    const currentTarget = e.currentTarget;

    if (relatedTarget instanceof Node && currentTarget instanceof Node) {
      const isMouseOutsidePopover =
          !relatedTarget || !currentTarget.contains(relatedTarget);

      if (isMouseOutsidePopover) {
        setShowPopover(false);
      }
    } else {
      setShowPopover(false);
    }
  };

  const holdingTankBack = () => {
    setHoldingTankUser(null); setTimeout(() => { setShowHoldingTank(false); }, 250);
  };

  const handleHoldingTank = () => {
    if (holdingTankUser && hoveredItemId) {
      let holdingTankPlacement = {
        parentId: hoveredItemId?.attributes?.parentId,
        position: hoveredItemId?.attributes?.position,
        userId: holdingTankUser?.id
      };

      holdingTankPlacementMutation.mutateAsync(holdingTankPlacement, {
        onSuccess: (res) => {
          if (res.status) {
            setHoldingTankUser(null);
            setTimeout(() => { setShowHoldingTank(false); }, 250);
            queryClient.invalidateQueries({ queryKey: ['holding-tank']});
            queryClient.invalidateQueries({ queryKey: ['genealogy-tree-list']});
            const { user, father, position } = res.data;
            toast.success(stringWithPlaceholders(t("holding_tank_placement_successful"), { placementName: user?.name, placementUsername: user?.username, fatherName: father?.name, fatherUsername: father?.username, position: t(position) }), { pauseOnHover: true, autoClose: 5000 });
          } else {
            toast.error(t(res?.description), { pauseOnHover: true, autoClose: 5000 });
          }
        }
      });
    }
  };

  const filterOptions = (inputValue) => {
    return props.holdingTankList?.filter((i) => !inputValue || i.fullName.toLowerCase().includes(inputValue.toLowerCase()) || i.username.toLowerCase().includes(inputValue.toLowerCase()));
  }

  const loadOptions = (inputValue, callback) => {
    callback(filterOptions(inputValue));
  };

  useEffect(() => {
    if (expanded) {
      anime({
        targets: listRef.current,
        translateY: [`${-20}px`, `${0}px`],
        opacity: [0, 1],
        duration: 500,
        easing: "easeInQuad",
      });
    }
  }, [expanded]);

  const popoverMenu = (
    <Popover id="treeview_pop_menu">
      <div className="card">
        {!showHoldingTank &&
          <div className="card-body d-grid gap-2">
            <button className="btn btn-primary" onClick={() => handleRegistration(hoveredItemId)}>{t("register")}</button>
            {props.holdingTankList?.length > 0 &&
                <button className="btn btn-primary" onClick={() => setShowHoldingTank(true)}>{t("placeHoldingTank")}</button>
            }
          </div>
        }
        {showHoldingTank &&
          <div className="card-body">
            <h5 className="card-title">{t("placeHoldingTank")}</h5>
            <AsyncSelect id="holdingTankUser" isClearable={true} defaultOptions loadOptions={loadOptions}
                         getOptionLabel={(option) => `${option.fullName} (${option.username})`}
                         getOptionValue={(option) => option.id}
                         onChange={(newValue) => setHoldingTankUser(newValue)}/>
            <div className="d-flex mt-2 gap-2 justify-content-between">
              <button className="btn btn-secondary" onClick={holdingTankBack}>{t("back")}</button>
              <button className={`btn btn-primary ${!holdingTankUser ? 'disabled' : ''}`} onClick={() => handleHoldingTank(holdingTankUser)}>{t("submit")}</button>
            </div>
          </div>
        }
      </div>
    </Popover>
  );

  return (
    <li>
      {data?.isPlaceholder ? (
        <>
          <div className="member-view-box">
            <div className="member-image">
              {data?.isMore && (plan === "Unilevel" || plan === "Party") ? (
                <>
                  {props.unilevelLoading === "fetching" &&
                    props.isMoreId.position !== null &&
                    data?.attributes?.fatherId === props.isMoreId?.fatherId &&
                    data?.attributes?.position === props.isMoreId?.position ? (
                    <Loader
                      type="spinner-default"
                      bgColor={"#485742"}
                      color={"#485742"}
                      size={25}
                    />
                  ) : (
                    <>
                      <div
                        className="right_more_user_expand_btn"
                        onClick={() => handleIsMore(data?.attributes)}
                      >
                        <i className="fa-solid fa-angle-double-right"></i>
                      </div>
                      <div className="member-details-dwnl-bx">
                        {data?.attributes?.moreChildren} more
                      </div>
                    </>
                  )}
                </>
              ) : (
                <OverlayTrigger
                  placement="auto"
                  trigger="click"
                  rootClose
                  delay="250"
                  overlay={popoverMenu}
                  show={showPopoverMenu}
                  onToggle={(nextShow) => {
                    if (nextShow) {
                      handleItemHover(data);

                      if (props.holdingTankList?.length === 0) {
                        handleRegistration(data);
                        return;
                      }
                    } else {
                      setHoldingTankUser(null);
                      setTimeout(() => { setShowHoldingTank(false); }, 250);
                    }

                    setShowPopoverMenu(nextShow);
                  }}
                >
                  <span className="pulse-button">+</span>
                </OverlayTrigger>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          {data?.attributes?.childrenCount > 0 &&
            data?.children?.length === 0 &&
            data?.attributes?.id === props?.selectedUserId ? (
            <div className="member-view-box">
              <div className="member-image">
                <Loader
                  type="spinner-default"
                  bgColor={"#485742"}
                  color={"#485742"}
                  size={25}
                />
              </div>
            </div>
          ) : (
            <>
              <span
                onClick={(e) => HandleClick(data, e)}
                onMouseEnter={() => setShowPopover(true)}
                onMouseLeave={handleMouseLeave}
              >
                <div className="member-view-box">
                  <OverlayTrigger
                    trigger="manual"
                    placement="auto"
                    delay="250"
                    overlay={<UserPopover noProfile={NoProfile} {...data?.tooltipData} containerSize={props.containerSize} />}
                    container={props.containerRef}
                    show={showPopover}
                  >
                    <div className="member-image">
                      <img src={data?.attributes?.treeIcon ?? NoProfile} alt="Member" />
                      <div className="member-details">
                        <h3>{data?.username}</h3>
                        {/* <i>{data?.attributes?.id}</i> */}
                        <div className="member-details-downline-dtl">
                          {plan === "Binary" ? (
                            <>
                              <div className="member-details-dwnl-bx">
                                {t("left")}:{" "}
                                <strong>
                                  {data?.attributes?.leftChildrenCount}
                                </strong>
                              </div>
                              <div className="member-details-dwnl-bx">
                                {t("right")}:{" "}
                                <strong>
                                  {data?.attributes?.rightChildrenCount}
                                </strong>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="member-details-dwnl-bx">
                                {t("teamCount")}:{" "}
                                <strong>
                                  {data?.attributes?.childrenCount}
                                </strong>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </OverlayTrigger>
                  {/* {data?.attributes?.childrenCount > 0 &&
                      data?.children?.length === 0 && (
                        <ul>
                          <li>
                            <div
                              className="right_more_user_expand_btn"
                              onClick={() => HandleExpand(data)}
                            >
                              <i className="fa-solid fa-angle-double-down"></i>
                            </div>
                          </li>
                        </ul>
                      )} */}
                </div>
              </span>
            </>
          )}
          {data?.children?.length > 0 && expanded && (
            <ul ref={listRef}>
              {data?.children?.map((child, index) => {
                return (
                  <TreeNode
                    key={index}
                    data={child}
                    holdingTankList={props.holdingTankList}
                    plan={plan}
                    ecomStatus={props.ecomStatus}
                    selectedUserId={props.selectedUserId}
                    setSelectedUserId={props.setSelectedUserId}
                    doubleUser={props.doubleUser}
                    setDoubleUser={props.setDoubleUser}
                    linkRegisterCheck={props.linkRegisterCheck}
                    setLinkRegisterCheck={props.setLinkRegisterCheck}
                    paramsList={props.paramsList}
                    setParamsList={props.setParamsList}
                    unilevelLoading={props.unilevelLoading}
                    setIsMoreId={props.setIsMoreId}
                    isMoreId={props.isMoreId}
                    updatedTree={props.updatedTree}
                    containerRef={props.containerRef}
                    containerSize={props.containerSize}
                  />
                );
              })}
            </ul>
          )}
        </>
      )}
    </li>
  );
};

const TreeNodeComponent = (props) => {
  const moduleStatus = useSelector((state) => state.dashboard?.appLayout?.moduleStatus) || JSON.parse(localStorage.getItem("moduleStatus") || "{}");

  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [linkRegisterCheck, setLinkRegisterCheck] = useState(false);
  const [paramsList, setParamsList] = useState({
    placement: "",
    position: "",
    isRegFromTree: "",
  });
  const [isMoreId, setIsMoreId] = useState({
    fatherId: null,
    position: null,
  });
  const genealogyList = useSelector((state) => state.tree?.genealogyList);
  const holdingTankList = useSelector((state) => state.tree?.holdingTankList);
  const updatedTree = ApiHook.CallUnilevelMore(isMoreId);
  const RegisterLink = ApiHook.CallRegisterLink(
    linkRegisterCheck,
    setLinkRegisterCheck,
    paramsList.placement,
    paramsList.position,
    paramsList.isRegFromTree
  );
  if (RegisterLink.isFetched) {
    window.location.href = RegisterLink.data?.link;
  }

  useEffect(() => {
    const container = props.containerRef.current;
    const observer = new ResizeObserver((entries) => {
      setContainerSize({ width: entries[0].contentRect['width'], height: entries[0].contentRect['height'] });
    });
    observer.observe(container);

    return () => {
      if(container){
        observer.unobserve(container);
      } else {
        observer.disconnect();
      }
    }
  });

  return (
    <TreeNode
      data={genealogyList}
      holdingTankList={holdingTankList?.data}
      plan={moduleStatus?.mlm_plan}
      ecomStatus={moduleStatus?.ecom_status}
      selectedUserId={props.selectedUserId}
      setSelectedUserId={props.setSelectedUserId}
      doubleUser={props.doubleUser}
      setDoubleUser={props.setDoubleUser}
      linkRegisterCheck={linkRegisterCheck}
      setLinkRegisterCheck={setLinkRegisterCheck}
      paramsList={paramsList}
      setParamsList={setParamsList}
      unilevelLoading={updatedTree.fetchStatus}
      setIsMoreId={setIsMoreId}
      isMoreId={isMoreId}
      updatedTree={updatedTree}
      containerRef={props.containerRef}
      containerSize={containerSize}
    />
  );
};

export default TreeNodeComponent;
