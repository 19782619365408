import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import SubmitButton from "../Common/buttons/SubmitButton";
import {ApiHook} from "../../hooks/apiHook";
import {toast} from "react-toastify";
import {useForm} from "react-hook-form";
import {useQueryClient} from "@tanstack/react-query";
import Select from "react-select";
import {useDispatch, useSelector} from "react-redux";
import {updateSettings} from "../../store/reducers/userReducer";

const SettingsTab = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const holdingTankStatus = useSelector((state) => state?.dashboard?.appLayout?.moduleStatus?.holding_tank_status);
    const settings = useSelector((state) => state?.user?.profile?.settings);
    const languages = useSelector((state) => state?.user?.profile?.languages);
    const currencies = useSelector((state) => state?.user?.profile?.currencies);

    const {
        register,
        watch,
        setValue,
        trigger,
        formState: {errors},
    } = useForm();

    const formValues = watch();
    const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
    const [languageOptions, setLanguageOptions] = useState([]);
    const [currencyOptions, setCurrencyOptions] = useState([]);

    const updateSettingsMutation = ApiHook.CallUpdateSettings();

    const toggleEditMode = () => {
        setIsEditModeEnabled(!isEditModeEnabled);
    };

    let binaryLegOptions = [
        {value: "any", label: t("any")},
        {value: "left", label: t("left")},
        {value: "right", label: t("right")},
        {value: "weak_leg", label: t("weakLeg")}
    ];

    if (holdingTankStatus) binaryLegOptions.push({value: "holding_tank", label: t("holdingTank")});

    useEffect(() => {
        if (settings) {
            setValue("language", settings.language);
            setValue("currency", settings.currency);
            setValue("binaryLeg", settings.binaryLeg);
        }
    }, [settings, setValue]);

    useEffect(() => {
        setLanguageOptions(
            languages.map((item) => {
                return {value: item.id, label: item.name};
            })
        );
    }, [languages]);

    useEffect(() => {
        setCurrencyOptions(
            currencies.map((item) => {
                return {value: item.id, label: item.name};
            })
        );
    }, [currencies]);

    const handleSubmit = async () => {
        const isValid = await trigger();
        if (isValid) {
            updateSettingsMutation.mutate(formValues, {
                onSuccess: (res) => {
                    if (res.status) {
                        dispatch(
                            updateSettings({
                                settings: formValues,
                            })
                        );
                        queryClient.invalidateQueries({queryKey: ["profile"]})
                        toast.success(t(res?.data));
                        setIsEditModeEnabled(false)
                    }
                },
            });
        }
    };

    return (
        <div id="sixthTab" className="tabcontent">
            <div className="editSec">
                <div className={`editSec ${isEditModeEnabled ? "disabled" : ""}`}>
                    <span className={"btn btn-link"} onClick={toggleEditMode}>
                      <i className="fa-solid fa-edit"></i>
                    </span>
                </div>
            </div>
            <h3>{t("settings")}</h3>
            <div className="tabcontent_form_section">
                {settings.language && (
                    <div className="mb-3 row tabBlockClass">
                        <label htmlFor="language" className="col-sm-3 col-form-label labelWidthClass">
                            {t("language")}:
                        </label>
                        <div className="col-md-9 col-12">
                            <Select
                                {...register("language")}
                                id="language"
                                value={languageOptions.find((item) => item.value === formValues?.language)}
                                isDisabled={!isEditModeEnabled}
                                isSearchable={false}
                                options={languageOptions}
                                onChange={(option) => setValue("language", option?.value)}
                            />
                        </div>
                    </div>
                )}
                {settings.currency && (
                    <div className="mb-3 row tabBlockClass">
                        <label htmlFor="currency" className="col-sm-3 col-form-label labelWidthClass">
                            {t("currency")}:
                        </label>
                        <div className="col-md-9 col-12">
                            <Select
                                {...register("currency")}
                                id="currency"
                                value={currencyOptions.find((item) => item.value === formValues?.currency)}
                                isDisabled={!isEditModeEnabled}
                                isSearchable={false}
                                options={currencyOptions}
                                onChange={(option) => setValue("currency", option?.value)}
                            />
                        </div>
                    </div>
                )}
                <div className="mb-3 row tabBlockClass">
                    <label htmlFor="binaryLeg" className="col-sm-3 col-form-label labelWidthClass">
                        {t("binaryPositionLock")}:
                    </label>
                    <div className="col-md-9 col-12">
                        <Select
                            {...register("binaryLeg")}
                            id="binaryLeg"
                            value={binaryLegOptions.find((item) => item.value === formValues?.binaryLeg)}
                            isDisabled={!isEditModeEnabled}
                            isSearchable={false}
                            options={binaryLegOptions}
                            onChange={(option) => setValue("binaryLeg", option?.value)}
                        />
                    </div>
                </div>
                <div className={`paymenytLinkBtn ${isEditModeEnabled ? "disabled" : ""}`}>
                    <SubmitButton
                        className="btn"
                        isSubmitting={!isEditModeEnabled}
                        text={t("update")}
                        click={handleSubmit}
                    />
                </div>
            </div>
        </div>
    );
};

export default SettingsTab;