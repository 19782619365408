import React from "react";
import { useTranslation } from "react-i18next";

const Enquiry = () => {
  const { t } = useTranslation();
  return (
    <div
      className="request__quote"
      style={{ backgroundImage: "url(/img/pages/request-quote.jpg)" }}
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-9 col-lg-9 col-md-8 md-mb-30">
            <div className="request__quote-title">
              {/*<h2>{t("enquire_now_for_more_details")}</h2>*/}
              <div className="request__quote-title-btn">
                <a className="btn-one" href="#contact">
                {t('contact_us')}!
                </a>
                {/*<img
                  className="left-right-animate"
                  src="/img/icon/arrow.png"
                  alt="quote-icon"
                />*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enquiry;
