import LoginService from "../../services/auth/Login";
import DashboardService from "../../services/dashboard/Dashboard";
import ProfileService from "../../services/profile/profile";

const setAuthLocalStorage = (data, setAll) => {
    localStorage.setItem("api-key", data?.apiKey);
    if (setAll) {
        localStorage.setItem("access-token", data.accessToken);
        localStorage.setItem("user", JSON.stringify(data.user));
        localStorage.setItem("defaultCurrency", JSON.stringify(data.defaultCurrency));
        //localStorage.setItem("defaultLanguage", JSON.stringify(data.defaultLanguage));
    }
};

const handleAuthResponse = (response, setAll = true) => {
    if (response.status) {
        setAuthLocalStorage(response?.data, setAll);
        return {status: response.status, data: response};
    }

    let result = {status: false, data: response?.description || response?.message};

    if (response?.code) result = {...result, code: response.code};

    return result;
};

export const loginUser = async (data) => {
    try {
        const response = await LoginService.authAccess(data);
        return handleAuthResponse(response, !response?.data?.twofaStatus);
    } catch (error) {
        return error;
    }
}

export const verify2faOtp = async (data) => {
    try {
        const response = await LoginService.verify2faOtp(data);
        return handleAuthResponse(response);
    } catch (error) {
        return error;
    }
}

export const fetchProfile = async () => {
    try {
        return await ProfileService.getProfile();
    } catch (error) {
        return error.message;
    }
};

export const PersonalDetailsUpdate = async (data) => {
    try {
        return await ProfileService.setPersonalData(data);
    } catch (error) {
        return error.message;
    }
};

export const ContactDetailsUpdate = async (data) => {
    try {
        return await ProfileService.setContactDetails(data);
    } catch (error) {
        return error.message;
    }
};

export const BankDetailsUpdate = async (data) => {
    try {
        return await ProfileService.updateBankDetails(data);
    } catch (error) {
        return error.message;
    }
};

export const SettingsUpdate = async (data) => {
    try {
        return await ProfileService.updateSettings(data);
    } catch (error) {
        return error.message;
    }
};

export const updateCurrency = async (body) => {
    try {
        return await DashboardService.multiCurrencyUpdation(body);
    } catch (error) {
        return error.message;
    }
};

export const updateLanguage = async (body) => {
    try {
        return await DashboardService.multiLanguageUpdation(body);
    } catch (error) {
        return error.message;
    }
};

export const updateProfileAvatar = async (body) => {
    try {
        return await ProfileService.updateUserProfilePic(body);
    } catch (error) {
        return error.message;
    }
}

export const logout = async () => {
    try {
        return await LoginService.logout();
    } catch (error) {
        return error.message;
    }
}

export const AdditionalDetails = async (data) => {
    try {
        return await ProfileService.updateAdditionalDetails(data);
    } catch (error) {
        return error.message
    }
}

export const PaymentDetails = async (data) => {
    try {
        return await ProfileService.updatePaymentDetails(data);
    } catch (error) {
        return error.message
    }
}

export const KycDetails = async () => {
    try {
        return await ProfileService.getKycDetails();
    } catch (error) {
        return error.message
    }
}

export const KycUpload = async (files) => {
    try {
        return await ProfileService.getkycUploads(files.files, files.category, files.type);
    } catch (error) {
        return error.message
    }
}

export const deleteKycFile = async (filesId) => {
    try {
        return await ProfileService.deleteKycFile(filesId);
    } catch (error) {
        return error.message
    }
}

export const deleteProfileAvatar = async () => {
    try {
        return await ProfileService.deleteProfileAvatar();
    } catch (error) {
        return error.message
    }
}

export const changePassword = async (body) => {
    try {
        return await ProfileService.changePassword(body);
    } catch (error) {
        return error.message
    }
}

export const changeTransactionPassword = async (body) => {
    try {
        return await ProfileService.changeTransactionPassword(body);
    } catch (error) {
        return error.message
    }
}

export const CompanyLogo = async () => {
    try {
        return await LoginService.companyLogo();
    } catch (error) {
        return error.message;
    }
}

export const getStripeAccountId = async () => {
    try {
        return await ProfileService.getStripeAccountId();
    } catch (error) {
        return error.message;
    }
}